@import url("https://fonts.cdnfonts.com/css/montserrat");
@import url("https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");

* {
    padding: 0;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
}

body {
    font-family: 'Montserrat', sans-serif;
}


section .body-container {
    width: 100%;
}

section .body-container .body-content {
    margin: 0 auto;
    width: 90%;
    padding: 20px;
}

section .body-container .body-content .password-reset-form input {
    width: 100%;
}

footer .footer-content-first img {
    width: 10%;
    margin: 5px 10px 0px 0px;
}

header .app-name img {
    width: 10%;
}

.header-content-lower .actual-nav {
    padding: 10px;
    width: 90%;
    margin: 0 auto;
}

.header-content-lower .actual-nav a {
    padding: 13px;
    color: #ffffff;
    text-decoration: none;
    font-size: 14px;
}

.header-content-lower .actual-nav a.active {
    background-color: #e6005c;
}

.header-content-lower .actual-nav a:hover {
    background-color: #70193c;
}

footer .footer-lower-content h4 {
    font-size: 17px;
}

section .app-main-container .banner-content {
    position: relative;
}

section .app-main-container .banner-content img {
    width: 100%;
}

section .app-main-container .banner-inner-content {
    position: absolute;
    color: red;
    width: 100%;
    background: #ffffff6b;
    bottom: 0px;
}

section .app-main-container .inner-content-wrapper {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.inner-content-item {
    width: 30%;
    position: relative;
    height: 300px;

}

.inner-content-item:first-child {
    background-image: url("./assets/images/keynote_2.jpeg");
    background-repeat: no-repeat;
    background-size: 100%;
}

.inner-content-item:nth-child(2) {
    background-image: url("./assets/images/Magazine.jpg");
    background-repeat: no-repeat;
    background-size: 55%;
    background-position-x: center;
}

.inner-content-item:nth-child(3) {
    background-image: url("./assets/images/main_banner.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
}

.inner-content-item:last-child {
    background-image: url("./assets/images/main_banner_1.jpeg");
    background-repeat: no-repeat;
    background-size: 100%;
}

.inner-content-item .item-summary {
    position: absolute;
    bottom: 0;
    background-color: #e6005cad;
    color: #ffffff;
    width: 100%;
    padding: 10px;
    top: 250px;
    transition: all .5s;
}

.inner-content-item .item-summary p {
    display: none;
    font-size: 14px;
}

.inner-content-item:hover .item-summary {
    top: 0px;
}

.inner-content-item:hover .item-summary p {
    display: block;
}

section .app-main-container .banner-inner-content .inner-content-item:nth-child(2) .item-image {
    display: flex;
    justify-content: center;
}

section .app-main-container .banner-inner-content .inner-content-item:nth-child(2) img {
    width: 53%;
}

section .app-main-container .banner-inner-content .inner-content-item:last-child .item-image {
    display: flex;
    justify-content: center;
}


.inner-content-item img {
    width: 100%;
}

header .header-content-lower .actual-nav {
    display: block;
}

section .meet-trasure {
    background-image: url("./assets/images/bricks.jpg");
    background-repeat: none;
    background-size: 100%;
}

section .meet-trasure .meet-treasure-container {
    background: #333333ad;
    color: #ffffff;
}

section .meet-trasure .meet-treasure-container .meet-treasure-content {
    width: 70%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

section .meet-trasure .meet-treasure-container .meet-treasure-content div {
    margin: 10px;
}

section .meet-trasure .meet-treasure-container .meet-treasure-content div .treasure-quotes {
    display: grid;
}

section .meet-trasure .meet-treasure-container .meet-treasure-content div .treasure-quotes small:last-child {
    color: #e6005c;
    font-weight: bold;
}

section .meet-trasure .meet-treasure-container .meet-treasure-content div:first-child img {
    border-radius: 50%;
}

section .video-content {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

section .video-content .video-frame {
    width: 30%;
    overflow: hidden;
    margin: 10px;
}

section .video-container {
    background-color: #333333;
    color: #ffffff;
}

section .video-content iframe {

    width: 100%;
}

section .contact-us-container {
    background-color: whitesmoke;
    padding: 10px;
}

section .contact-us-container .contact-us-content {
    width: 90%;
    margin: 0 auto;
}

section .contact-us-container .contact-us-content .contact-us-form {
    width: 30%;
    padding: 1% 0 2% 0;
}

section .about-us-container .about-us-banner {
    position: relative;
}

section .about-us-container .about-us-banner .about-banner-summary {
    width: 100%;
    background: #333333cc;
    position: absolute;
    bottom: 0;
    height: 200px;
    padding: 10px 0 0 0;
}

section .about-us-container .about-us-banner .about-banner-summary div {
    width: 90%;
    margin: 0 auto;
    color: #ffffff;
}

section .about-us-container .about-us-banner .about-banner-summary p b {
    color: #e6005c;
}

section .about-us-container .about-us-banner img {
    width: 100%;
}

section .about-us-container .about-us-content {
    background-image: url("./assets/images/bricks.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
}

section .about-us-container .about-us-content .about-us-content-overlay {
    background-color: #333333c7;
}

section .about-us-container .about-us-content .about-us-content-overlay .content-wrapper {
    width: 80%;
    margin: 0 auto;
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

section .about-us-container .about-us-content .about-us-content-overlay .content-wrapper .about-image img {
    border-radius: 50%;
    width: 80%;
}

section .about-us-container .about-us-content .about-us-content-overlay .content-wrapper .about-content {
    color: #ffffff;
}

section .about-what-others-think {
    background-color: #cccccc;
}

section .about-what-others-think .content-slider {
    width: 60%;
    margin: 0 auto;
    padding: 10px;
}

section .about-what-others-think .content-slider .slides {
    display: flex;
    justify-content: space-around;
}

section .about-what-others-think .content-slider .slides .inner-content-item {
    background-size: cover;
    background-image: url("./assets/images/bricks.jpg");
}

section .about-what-others-think-content {
    padding: 10px;
    width: 90%;
    margin: 0 auto;
}

section .about-what-others-think-content h4 {
    text-align: center;
}

section .about-what-others-think .slider-controls {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

section .slider-content .slides {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

section .services-container {
    background-image: url("./assets/images/home_sec.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
}

section .services-container .services-content {
    width: 90%;
    margin: 0 auto;
    color: #ffffff;
    padding: 10px;
}

section .services-container .services-content h4 {
    text-align: center;
}

section .services-container .services-content .service-item {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 5% 0 0 0;
}

section .services-container .services-content .service-item .service-icon {
    width: 30%;
}

section .services-container .services-content .service-item .service-icon .app-main-btn {
    width: 60% !important;
}

.app-main-btn:hover {
    background-color: #70193c !important;
}

section .additional-services-content {
    background: #333333;
}

section .additional-services-content .services-box {
    display: flex;
    width: 90%;
    padding: 10px;
    margin: 0 auto;

}

section .additional-services-content .services-box .inner-content-item {
    background-size: cover;
    background-image: url("./assets/images/main_banner_1.jpeg");
    margin: 10px;
}

section .additional-services-content .services-box .inner-content-item h4 {
    font-size: 16px;
}

section .get-quote-container {
    background: whitesmoke;
}

section .get-quote-container .get-quote-content {
    width: 90%;
    margin: 0 auto;
    padding: 10px;
}

section .get-quote-container .get-quote-content .get-quote-content-form {
    width: 60%;
}

section .get-quote-container .get-quote-content .get-quote-form {
    width: 50%;
}

section .additional-contacts p {
    margin: 0;
}

section .downloads-container {
    background-image: url("./assets/images/bricks.jpg");
    background-size: 100%;
    background-repeat: inherit;
}

section .downloads-overlay {
    background-color: #333333d0;
}

section .downloads-content {
    width: 90%;
    padding: 10px;
    margin: 0 auto;
    color: #ffffff;
}

section .downloads-content .downloads-images-container img {
    width: 70%;
    cursor: pointer;
}

section .downloads-content .downloads-images-container {
    width: 30%;
    margin: 3% 0 20px 0;
}

section .banking-container,
section .profile-container,
section .write-container,
section .blog-container {
    background-color: whitesmoke;
    padding: 10px;
}

section .blog-container .blog-content {
    width: 90%;
    margin: 0 auto;
}

.blog-upper-links span {
    cursor: pointer;
    padding: 10px;
    font-size: 10px;
    color: #e6005c;
    font-weight: bold;
}

.blog-upper-links span:hover {
    color: #e6005c;
}

.blog-upper-links .for-user-details {
    width: 2%;
    border-radius: 50%;
    padding: 2px;
}

section .article-details {
    padding: 10px;
    text-align: left;
    color: #000000;
    font-size: 13px;
    background-color: #cccccc;
    margin: 10px;
    display: flex;
}

section .article-details span {
    display: flex;
    margin: 0 10px 0 10px;
    cursor: pointer;
}

section .article-details span small:last-child {
    padding: 2px 0 0 5px;
}

section .article-details div {
    padding: 0px 10px 10px 10px;
}

section .article-details div svg {
    margin: 10px 0 0 0;
}

section .article-container {
    display: flex;
    justify-content: space-between;
}

section .article-container .article-content {
    width: 60%;
    margin: 10px;
}

section .article-summary {
    padding: 1% 0 0 0;
    border-bottom: 1px solid #333333;
}

section .article-summary pre {
    white-space: pre-wrap;
    font-family: 'montserrat';
}

.article-image {
    max-height: 200px;
    width: 100%;
    overflow: hidden;
    margin: 10px;
}

.article-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.p-password-input {
    display: block !important;
    width: 100% !important;
    padding: 0.375rem 0.75rem !important;
    font-size: 1rem !important;
    line-height: 1.5 !important;
    color: #495057 !important;
    background-color: #ffffff !important;
    background-clip: padding-box !important;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
}

section .article-comments {
    width: 50%;
    padding: 2% 0 0 0;
    position: relative;
}

section .mentions-pop-up {
    background: #cccccc;
    width: 100%;
    border-radius: 5px;
    transition: all .5s;
    max-height: 110px;
    top: -6px;
    box-shadow: 2px 2px 2px #3333331f;
    overflow-y: auto;
    margin: 0 0 10px 0;
}

section .mentions-pop-up ul li {
    list-style-type: none;
    cursor: pointer;
    padding: 10px 0 0 10px;
}

section .article-comments textarea {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

section .comment-holder {
    display: flex;
    margin: 10px 0 0 0px;
}

section .comment-holder .user-initials {
    width: 40px;
    height: 40px;
    background: #70193c;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    color: #ffffff;
}

section .comment-user-names {
    padding: 1px 0 0 10px;
    display: grid;
    cursor: pointer;
}

section .comment-user-names span {
    margin: 0;
    padding: 0;
}

section .comment-user-names small {
    padding: 0;
    margin: 0;
    font-size: 10px;
}

section .comment-message {
    padding: 10px 0 0 50px;
    font-size: 14px;
}

section .comment-user-icon {
    cursor: pointer;
}

section .delete-comment {
    padding: 5px 0 0 10px;
    cursor: pointer;
}

section .comments-main-container {
    overflow-y: auto;
    padding: 2% 0 0 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
section .comments-main-container::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
section .comments-main-container {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

section .profile-content,
section .banking-content,
section .write-content {
    width: 90%;
    margin: 0 auto;
}

section .profile-content .card {
    border: none !important
}

section .write-article-stats {
    display: flex;
    margin: 10px;
    justify-content: space-between;
}

section .writing-article-form .app-main-btn {
    margin: 10px !important;
}

section .writing-article-form .card,
section .writing-article-form span {
    margin: 10px;
    color: red;
}

section .writing-article-form .card {
    width: 60% !important;
}

section .writing-article-form span {
    cursor: pointer;
}

section .writing-article-preview {
    background-color: #ffffff;
    margin: 10px 0 10px 0;
    width: 60%;
    padding: 10px 10px 5% 0px;
}

section .writing-article-form fieldset {
    margin: 10px;
}

section .writing-article-form fieldset input[name="ArticleName"] {
    width: 30%;
}

section .article-summary pre,
section .writing-article-preview pre {
    font-family: 'Montserrat', sans-serif;
}

section .write-article .article-image {
    width: 60%;
    margin: 10px;
    overflow: hidden;
}

section .write-article h4 {
    padding: 0 0 0 10px;
}

section .article-footer {
    color: #e6005c;
    cursor: pointer;
    margin: 10px;
}

section .article-footer span small {
    margin: 10px 10px 0 0;
}

.article-additional-info {
    display: none;
    padding: 0 0 0 10px;
}

.article-additional-info fieldset {
    margin: 10px 10px 0 0;
}

.article-stats {
    display: flex;
    transform: all .5s;
}

section .banking-data {
    margin: 10px;
}

section .bank-card {
    width: 30%;
    background: #333333;
    color: #ffffff;
    border-radius: 10px;
}

section .bank-card .card-logo img {
    width: 15%;
}

section .bank-card .card-body {
    display: grid;
}

section .bank-card .card-body span {
    margin: 10px 0 10px 0;
}

section .banking-form {
    margin: 10px 0 10px 0;
    width: 30%;
}

section .blog-upper-links .active {
    color: #e6005c;
}

section .user-personal-details {
    margin: 20px 10px 20px 10px;
}

section .user-personal-details .user-image {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: 2px solid #e6005c;
    overflow: hidden;
}

section .follow-card .follower-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin: 3px 0 3px 3px;
    background-size: cover !important;
    background-repeat: no-repeat !important;
}

section .follow-card .follower-info {
    padding: 0 0px 0px 10px !important;
    width: 75% !important;
}

section .follow-card .follower-image p {
    text-align: center;
    font-weight: bold;
    background: #e6005c;
    line-height: 50px;
    height: 50px;
    color: #ffffff;
}

section .follow-card .follower-names {
    margin: 6px 0 0px 0px;

}

section .follow-card .follower-names p:last-child {
    margin-top: 0;
    margin-bottom: 0;
}

section .for-articles-ribbon .follower-names {
    padding: unset !important;
}

section .for-articles-ribbon .follower-info {
    width: 85% !important;
    font-size: 13px !important;
}

section .personal-details-form {
    width: 50%;
}

section .personal-details-form select,
section .personal-details-form input {
    width: 70%;
}

section .for-articles-ribbon .follower-image {
    margin: 8px 0 3px 3px !important;
}

section .follow-card .follower-options {
    padding: 16px 10px 0px 25px;
}

section .follow-card .follower-names p:first-child {
    margin-bottom: 0;
    margin-bottom: 0;
    font-size: 13px;
}

section .p-tabview-panels .user-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    border: 5px solid #e6005c;
}

section .p-tabview-panels .user-image-container span {
    display: block;
    cursor: pointer;
    color: #e6005c;
    margin: 10px 0 10px 0;
}

section .blog-upper-links {
    cursor: pointer;
}

section .p-tabview-panels .user-image img {
    width: 100%;
}

section .user-form {
    width: 30%;
}

section .user-form .app-main-btn {
    width: 60% !important;
}

section .follow-card .follower-names p:nth-child(2) {
    font-weight: bold;
    margin-bottom: 0;
}

section .search-results .follow-card .follower-names p:nth-child(2) {
    font-weight: unset !important;
    font-size: 13px;
}

section .follow-card .follower-image img {
    width: 100%;
}

section .follow-card:hover {
    background-color: #cccccc;
}

section .follow-card {
    width: 30%;
    background: whitesmoke;
    margin: 5px 0 5px 0;
    cursor: pointer;
}

section .follow-card .follower-content {
    display: flex;
    justify-content: space-between;
}

section .user-personal-details span {
    display: block;
    padding: 20px 0 20px 0;
    cursor: pointer;
    display: flex;
    color: #e6005c;
}

section .user-personal-details span small {
    margin: 5px;
    display: block;
}

section .user-personal-details .user-image img {
    width: 100%;
    object-fit: contain;
}

section .user-personal-details label {
    font-weight: bold;
}

section .stats {
    display: flex;
    cursor: pointer;
    background: #333333;
    color: #ffffff;
    margin: 0 0 10px 0;
}

section .stats p {
    margin: 10px;
}

section .stats p:hover {
    color: #e6005c;
}

section .user-personal-details .user-form {
    width: 30%;
}

section .password-form {
    width: 30%;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #e6005c !important;
    background: #e6005c !important;
}

section .article-selection {
    margin: 10px;
    width: 30%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.writing-article-preview pre::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.writing-article-preview pre {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

section .downloads-list {
    display: flex !important;
    flex-wrap: wrap !important;
    width: 100% !important;
}

section .report-article {
    color: red;
    font-weight: bold;
    cursor: pointer;
}

section .password-reset-form {
    width: 30%;
}

section .admin-app-container {
    padding: 10px;
}

section .admin-app-content {
    width: 90%;
    margin: 0 auto;
}

section .admin-app-content .reporting {
    display: flex;
}

section .reporting .reports-container,
section .reporting .panel-container {
    margin: 10px;
    padding: 10px;
}

section .reporting .reports-container {
    border: none;
    width: 100%;
}

section .reporting .panel-container ul li {
    list-style-type: none;
    padding: 10px;
}

section .reporting .panel-container ul li:hover {
    cursor: pointer;
    color: #70193c;
}

section .all-reports {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

section .all-reports .counts {
    text-align: center;
    height: 100px;
    background: #333333;
    color: #ffffff;
    margin: 10px 0 10px 0;
    width: 20%;
}

section .all-reports .counts span {
    display: block;
    padding: 10px;
}

section .all-reports .counts .count-content {
    display: flex;
    justify-content: center;
}

section .detailing-write-earn {
    width: 50%;
}

section .recent-article-header {
    margin: 10px;
}

section .articles-ribbon {
    width: 40%;
    overflow: hidden;
    height: 50px;
    background: #333333;
    color: #ffffff;
    margin: 10px;
    cursor: pointer;
}

section .articles-ribbon .ribbon-container {
    display: flex;
}

section .articles-ribbon .ribbon-article-image {
    width: 100px;
    height: 100px;
    background-repeat: no-repeat;
    background-size: contain;
}

section .articles-ribbon .ribbon-article-image img {
    width: 100%;
}

section .articles-ribbon .article-description small,
section .articles-ribbon .article-description p {
    margin: 2px;
    margin-left: 10px;
}

section .dropdown-filters select {
    margin: 10px 0 10px 0;
}

section .articles-ribbon-container .card {
    width: 20% !important;
    height: 320px;
    margin: 10px 10px 0px 10px;
}

section .articles-ribbon-container .card h5 {
    margin: 10px;
    padding: 0 5px 0 5px;
    background: #efd6ef;
}

section .articles-ribbon-container .card h6 {
    margin: 10px;
    padding: 0 5px 0 5px;
}

section .articles-ribbon-container .card .card-text {
    margin: 0;
    padding: 0;
    font-size: 13px;
}

section .articles-ribbon-container .card .card-image {
    height: 150px;
    overflow: hidden;
}

section .articles-ribbon-container .card .card-body {
    padding: 0 10px 0 10px;
}

section .articles-ribbon-container .card span:hover {
    color: #000000;
}

section .articles-ribbon-container .card span {
    display: block;
    padding: 10px;
    cursor: pointer;
    color: red;
}

section .all-articles,
section .most-recent-container {
    display: flex;
    flex-wrap: wrap;
}

section .controls-container button {
    margin: 10px;
}

section .controls-container button:first-child {
    margin: 10px 0 10px 0;
}

.back-drop {
    background: #33333345;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 100;
    top: 0;
    display: none;
}

.back-drop-on-display {
    display: block;
}

section .comment-likes-holder {
    display: flex;
    justify-content: space-between;
    width: 40%;
}

section .comment-likes-holder span {
    cursor: pointer;
    color: #e6005c;
}

section .comment-likes-holder span:hover {
    color: #70193c;
}

section .mobile-app-content {
    text-align: center;
    width: 90%;
    margin: 0 auto;
    cursor: pointer;
}

section .mobile-app-container {
    background-color: #F8F7F1;
}

section .mobile-app-content img {
    cursor: pointer !important;
}

section .for-app-images-content {
    display: flex;
    width: 100%;
}

section .for-app-images-content .app-message {
    width: 45%;
    text-align: left;
    margin-top: 5%;
}

section .for-app-images-content .app-message p {
    width: 60%;
}

section .mobile-app-images {
    width: 50%;
    margin: 0 auto;
    display: flex;
    text-align: center;
    justify-content: right;
}

section .mobile-app-images img {
    width: 20%;
    margin: 10px;
}

.image-viewer {
    position: fixed;
    width: 100%;
    z-index: 100;
    top: 0;
    text-align: center;
    background: #33333385;
    height: 100%;
}

.image-viewer img {
    width: 50%;
    margin-top: 5%;
}

section .follow-and-favorite {
    cursor: pointer;
}

section .follow-and-favorite i {
    font-size: 30px !important;
}

@media screen and (max-width: 1024px) {
    section .body-container .body-content .password-reset-form button {
        width: 100%;
        margin: 5px;
    }

    section .for-app-images-content {
        display: grid;
        width: 100%;
    }

    section .mobile-app-images {
        width: 100%;
        margin: 0 auto;
        display: flex;
        text-align: center;
        justify-content: right;
    }

    section .for-app-images-content .app-message {
        width: 100%;
        text-align: left;
        margin-top: 5%;
    }

    section .mobile-app-images img {
        width: 30%;
        margin: 10px 0 10px 10px;
    }

    .image-viewer img {
        width: 100%;
        margin-top: 50%;
    }

    section .for-app-images-content .app-message p {
        width: unset !important;
    }

    section .comment-likes-holder {
        width: 40%;
    }

    section .unpublished-articles {
        width: 100% !important;
        margin: 10px 0 10px 0 !important;
    }

    section .article-container .article-content {
        width: 100% !important;
    }

    section .p-tabview-panels .user-image-container {
        text-align: center;
    }

    section .user-form {
        width: 100%;
    }

    section .p-tabview-panels .user-image {
        margin: 0 0 0 25%;
    }

    section .unpublished-articles .app-main-btn,
    section .charts-container .app-main-btn {
        width: 100% !important;
    }

    section .follow-card {
        width: 100%;
    }

    section .all-articles,
    section .most-recent-container {
        display: flex;
        flex-wrap: wrap;
    }

    section .articles-ribbon-container .card {
        width: 100% !important;
        height: 320px;
        margin: 10px 10px 0px 0px;
    }

    section .admin-app-content {
        width: 100%;
        margin: 0 auto;
    }

    section .articles-ribbon .ribbon-article-image {
        width: 30% !important;
    }

    section .articles-ribbon .ribbon-container {
        width: 100%;
    }

    section .dropdown-filters {
        margin: 10px 0 10px !important;
        width: 100% !important;
    }

    section .articles-ribbon-container h6 {
        margin: 10px 0 10px 0 !important;
    }

    section .article-description {
        width: 70%;
    }

    section .detailing-write-earn {
        width: 100%;
    }

    section .blog-content .show-hide-article {
        width: 100% !important;
        margin: 10px 0 10px 0 !important;
    }

    section .all-reports .counts {
        width: 100%;
    }

    section .password-reset-form {
        width: 100%;
    }

    section .articles-ribbon {
        width: 100%;
        margin: 10px 0 10px 0px;
    }

    section .detailing-write-earn .app-main-btn {
        width: 100% !important;
    }

    section .body-container .body-content {
        margin: 0 auto;
        width: 95%;
        padding: 10px;
    }

    section .writing-article-form .card {
        width: unset !important;
    }

    .form-content .app-main-btn {
        width: 100% !important;
    }

    #reset-password-footer {
        position: unset !important;
    }

    section .downloads-content .downloads-images-container img {
        width: 100%;
    }

    section .downloads-container {
        background-repeat: unset;
    }

    section .downloads-content .downloads-images-container .app-main-btn {
        width: 100% !important;
    }

    section .user-personal-details .user-form {
        width: 100%;
    }

    section .user-image-container .app-main-btn {
        width: 100% !important;
    }

    section .password-form {
        width: 100%;
    }

    section .article-details span small:last-child {
        padding: 0px 0 0 5px;
    }

    section .banking-data .app-main-btn {
        width: 100% !important;
    }

    section .blog-container .blog-content {
        width: 100%;
    }

    section .article-summary pre,
    section .writing-article-preview pre {
        text-align: justify !important;
        white-space: break-spaces;
    }

    section .article-container .article-content {
        width: 100%;
        margin: 10px 0 10px 0;
    }

    section .article-comments {
        width: 100%;
        padding: 2% 0 0 0;
    }

    section .comments-main-container {
        height: auto;
    }

    section .article-details span {
        display: flex;
        margin: 0 10px 0 10px;
        cursor: pointer;
        width: 40%;
    }

    .article-additional-info fieldset {
        margin: 10px 0px 0 0;
    }

    section .article-details {
        flex-wrap: wrap;
        font-size: 16px;
    }

    .article-additional-info {
        display: none;
        padding: 10px 0 0 0;
    }

    .article-stats {
        display: grid;
    }

    section .article-comments .app-main-btn {
        width: 100% !important;
    }

    section .downloads-content .downloads-images-container {
        width: 100%;
    }

    section .stats {
        display: grid;
        text-align: center;

    }

    section .article-selection {
        margin: 0 auto;
        width: 95%;
    }

    section .write-article-stats {
        display: grid;
    }

    section .writing-article-preview {
        margin: 0 auto;
        width: 95%;
    }

    section .get-quote-container .get-quote-content .get-quote-content-form,
    section .get-quote-container .get-quote-content .get-quote-form .app-main-btn,
    section .get-quote-container .get-quote-content .get-quote-form {
        width: 100% !important;
    }

    div[role="dialog"] {
        width: 90vw !important;
    }

    section .app-content {
        justify-content: center !important;
        width: unset !important;
        padding: 2% 0px;
        cursor: pointer;
        margin: 0px auto;
        display: grid !important;
    }

    .smart-resume-holder img {
        margin: 20px 0 0 0 !important;
    }

    .footer-creds {
        display: grid !important;
    }

    section .writing-article-form fieldset input[name="ArticleName"] {
        width: 100%;
    }

    footer .footer-lower-content {
        display: grid !important;
        justify-content: unset !important;
    }

    footer .footer-lower-content div {
        width: 100% !important;
        margin: 10px 0 10px 0;
    }

    section .app-main-container .banner-inner-content {
        position: relative;
    }

    section .app-main-container .inner-content-wrapper {
        display: grid;
        justify-content: unset !important;
    }

    .inner-content-item {
        width: 100%;
        margin: 10px 0 10px 0;
    }

    .header-content-lower .actual-nav {
        display: none;
    }

    header .app-name img {
        width: 30%;
    }

    section .app-main-container .banner-inner-content .inner-content-item:nth-child(2) {
        background-size: contain;
    }

    section .app-main-container .banner-inner-content .inner-content-item:first-child,
    section .app-main-container .banner-inner-content .inner-content-item:last-child,
    section .app-main-container .banner-inner-content .inner-content-item:nth-child(3) {
        background-size: cover;
    }

    .header-content-lower .actual-nav a {
        display: block;
    }

    section .follow-and-favorite {
        display: grid !important;
    }

    section .follow-and-favorite i {
        font-size: 30px !important;
    }

    header .app-mobile-nav .bar {
        display: block;
        width: 25px;
        height: 2px;
        background-color: #e6005c;
        margin: 4px;
    }

    header .header-content {
        display: flex;
        width: 100% !important;
        justify-content: space-between;
    }

    header .app-mobile-nav {
        padding: 20px 0px 0px 0px;
        cursor: pointer;
    }

    section .meet-trasure .meet-treasure-container .meet-treasure-content {
        width: 100%;
        display: grid;
    }

    section .meet-trasure .meet-treasure-container .meet-treasure-content div:first-child img {
        width: 100%;
    }

    section .video-content {
        width: 100%;
        display: grid;
        padding: unset;
        justify-content: unset;
    }

    section .video-content .video-frame {
        width: 100%;
    }

    section .video-content iframe {
        margin: 10px 0 10px 0;
        width: 95%;
    }

    section .contact-us-form,
    section .contact-us-form .app-main-btn,
    section .contact-us-container .contact-us-content .contact-us-form {
        width: 100% !important;
    }

    .inner-content-item img,
    section .contact-us-banner img {
        width: 100%;
    }

    section .video-container {
        width: 100%;
        overflow: hidden;
    }

    section .about-us-container .about-us-content .about-us-content-overlay .content-wrapper {
        display: grid;
        justify-content: unset;
        width: 100%;
        text-align: center;
    }

    section .about-us-container .about-us-content .about-us-content-overlay .content-wrapper .about-content {
        padding: 10px;
        text-align: left;
    }

    section .about-what-others-think .content-slider {
        width: 100%;
        margin: 10px 0 10px 0;
    }

    section .about-us-container .about-us-banner .about-banner-summary {
        position: unset;
        padding: unset;
    }

    section .about-us-container .about-us-banner .about-banner-summary div h4 {
        padding: 0;
        margin: 0;
    }

    section .about-what-others-think .content-slider .slides {
        display: grid;
        justify-content: unset;
    }

    section .services-container {
        background-repeat: unset;
    }

    section .services-container .services-content .service-item {
        width: 100%;
        margin: 0 auto;
        display: grid;
        justify-content: unset;
    }

    section .services-container .services-content .service-item .service-icon {
        width: 100%;
        margin: 10px 0 10px 0;
        text-align: center;
    }

    section .services-container .services-content .service-item .service-icon .app-main-btn {
        width: 100% !important;
    }

    section .additional-services-content .services-box .inner-content-item {
        margin: 10px 0 10px 0;
    }

    section .additional-services-content .services-box {
        display: grid;
        justify-content: unset;
        padding: unset;
    }

    section .banking-form {
        margin: 10px 0 10px 0;
        width: 100%;
    }

    section .bank-card .card-logo img {
        width: 30%;
    }

    section .bank-card {
        width: 100%;
    }

    section .profile-content,
    section .banking-content,
    section .write-content {
        width: 100%;
    }
}