header {
    background-color: whitesmoke;
}

header .blog-header-content {
    width: 100%;
    overflow: hidden;
}

header .blog-header-content .blog-header-inner-content {
    width: 90%;
    overflow: hidden;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
}

header .blog-header-inner-content .app-blog-name img {
    width: 5%;
}

header .app-nav ul {
    display: flex;
    list-style-type: none;
}

header .app-nav ul li {
    padding: 15px 10px 0px 10px;
    font-size: 13px;
    cursor: pointer;
}

header .app-nav ul li:hover {
    color: #e6005c;
    cursor: pointer;
}

header .app-nav .appuser-loggedin li:not(:nth-child(1)) {
    padding: 20px 0 0 10px;
}

section .search-section-content {
    width: 100%;
    overflow: hidden;
}

section .search-section-content .search-content-container {
    width: 70%;
    margin: 0 auto;
    padding: 2% 0 0 0;
}

section .search-input input {
    border: none;
    border-bottom: 1px solid #cccccc;
    width: 40%;
    padding: 10px;
    font-size: 14px;
    border: none;
    border-bottom: 1px solid #cccccc;
    border-radius: 0;
}

section .search-results {
    width: 40%;
    background: #f5f5f5fc;
    margin: 10px 0 0 0;
    padding: 10px;
    height: 300px;
    position: absolute;
    z-index: 10;
    overflow: hidden;
    overflow-y: auto;
}

section .search-results ul li {
    list-style-type: none;
    margin: 10px 0 10px 0;
    cursor: pointer;
}

section .search-results ul li:hover {
    color: #e6005c;
}

section .search-input .card {
    border: none !important;
}

section .search-input input:focus {
    outline: none;
    box-shadow: none !important;
}


section .recently-published-content {
    width: 100%;
    overflow: hidden;
}

section .recently-content-container {
    width: 70%;
    margin: 0 auto;
    padding: 3% 0 0 0;
}

section .recently-card {
    height: 200px;
    width: 25%;
    background-repeat: no-repeat;
    border-radius: 5px;
    background-size: cover !important;
    margin: 10px 10px 10px 0px;
    position: relative;
    box-shadow: 2px 2px 2px #0000005c;
}

section .recently-card .recently-card-backdrop {
    background: #333333d1;
    height: 100%;
    border-radius: 5px;
}

section .recently-card-header {
    padding: 10px;
    font-size: 11px;
    color: #ffffff;
}

section .recently-card-body {
    padding: 20px 20px 20px 10px;
    color: #ffffff;
}

section .recently-card-footer .app-main-btn {
    width: 50% !important;
    margin: 10px !important;
    color: #ffffff !important;
    color: #ffffff !important;
    position: absolute !important;
    bottom: 0 !important;
}

section .recently-published-scrollable-horizontally {
    display: flex;
    width: 80%;
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;
}

section .recently-published-scrollable {
    display: flex;
    flex-wrap: wrap;
}

/* Hide scrollbar for IE, Edge and Firefox */
section div {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

section .all-topics {
    display: flex;
    justify-content: space-between;
    width: 80%;
    overflow: hidden;
    overflow-x: auto;
}

html {
    scrollbar-width: none;
}

section .all-topics span:hover {
    background-color: #e6005c;
}

section .all-topics .active-topic {
    background-color: #e6005c;
}

section .all-topics span {
    margin: 5px 5px 5px 5px;
    display: block;
    background-color: #333333;
    color: #ffffff;
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    white-space: nowrap;
    font-size: 13px;
    box-shadow: 2px 2px 2px #0000005c;
}

section div::-webkit-scrollbar {
    display: none;
}

.loader-container {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 30%);
    top: 30%;
}

.loading {
    border: 4px solid #e6005c;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border-right-color: transparent;
    animation: rot 1s linear infinite;
}

section .blog-article-content {
    width: 70%;
    margin: 0 auto;
    padding: 2% 0 0 0;
}

section .blog-header-options {
    display: flex;
    background: whitesmoke;
    justify-content: space-evenly;
    box-shadow: 2px 2px 2px #cccccc;
    border-radius: 5px;
}

section .blog-header-options span {
    display: block;
    padding: 10px;
    cursor: pointer;
}

section .blog-header-options span:hover {
    color: #e6005c;
}

section .blog-header-options i {
    font-size: 20px !important;
}

section .blog-article-body pre {
    padding: 10px 0 0;
    white-space: pre-wrap;
    font-family: Montserrat, sans-serif !important;
}

section .blog-article-image {
    height: 250px;
    margin: 10px 0 0 0;
    background-size: auto !important;
    background-repeat: no-repeat !important;
}

section .blog-article-header p:last-child {
    padding: 5px 0 0 0;
}

section .article-is-disliked,
section .article-is-liked {
    color: #e6005c;
}

@keyframes rot {
    100% {
        transform: rotate(360deg);
    }
}

footer .active-link span {
    color: #e6005c !important;
}

section .app-banking-content,
section .app-profile-content,
.app-profile-content,
section .writing-content {
    width: 70%;
    margin: 0 auto;
    padding: 2% 0 0 0;
}

section .app-profile-content {
    padding: 0;
}

section .app-profile-content .bi-images {
    color: #e6005c;
    position: absolute;
    font-size: 50px;
    left: 50%;
    transform: translate(-50%, 10px);
    z-index: 100;
    cursor: pointer;
}

section .image-view {
    position: fixed;
    z-index: 100000;
    width: 100%;
    height: 100%;
    background: #cccccc8a;
    top: 0;
}

section .image-view .view-container {
    width: 500px;
    height: 500px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 50%;
    padding: 5% 0 0 0;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

section .app-profile-content .card {
    border-radius: 0 !important;
}

section .gen-referral {
    width: 40% !important;
}

section .app-profile-pic-container {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    background-size: cover !important;
    background-repeat: no-repeat;
    border: 5px solid #e6005c;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0px);
    top: 200px;
    background: #ffffff;
    color: #e6005c;
    text-align: center;
}

section .app-profile-pic-container p {
    font-size: 100px;
    padding: 15px 0 0 0;
    color: #000000;
}

section .app-profile-bio {
    padding: 12% 0 0 0;
    text-align: center;
    width: 100%;
    display: grid;
    justify-content: center;
}

section .app-profile-bio .app-main-btn {
    width: 100% !important;
}

footer .footer-menu {
    padding: 10px;
    background: #333333;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 100;
    display: none;
}

footer .footer-menu-content span {
    font-size: 20px;
    color: #ffffff;
    display: block;
}

footer .default-app-menu,
footer .is-logged-in-menu {
    display: flex;
    justify-content: space-around;
}

footer .default-app-menu .user-profile-pic span {
    font-size: 12px;
    padding: 5px 0 0 3px;
    display: block;
}

footer .default-app-menu .user-profile-pic {
    height: 30px;
    width: 30px;
    background-size: cover !important;
    border-radius: 50%;
    border: 2px solid #ffffff;
}

footer .default-app-menu .active-link .user-profile-pic span {
    border: unset;
}

footer .default-app-menu .active-link .user-profile-pic {
    border: 2px solid #e6005c;
}

@media screen and (max-width: 1024px) {
    section .search-section-content .search-content-container {
        width: 100%;
        margin: 10px;
    }

    footer .footer-menu {
        display: block;
    }

    section .search-input input {
        width: 90%;
        padding: 10px;
        border-radius: 0;
    }

    section .recently-content-container {
        width: 100%;
        margin: unset;
        padding: 3% 0 0 0;
    }

    section .recently-content-container h4 {
        margin: 10px;
    }

    section .recently-published-scrollable-horizontally {
        width: 100%;
    }

    section .recently-card {
        width: 100%;
        margin: 10px;
    }

    section .search-results {
        width: 100%;
        margin: unset;
        left: 50%;
        transform: translate(-50%, 0);
    }

    section .all-topics {
        width: 100%;
        margin: unset;
    }

    header .app-nav {
        display: none;
    }

    header .app-nav ul {
        display: grid;
        width: 100%;
    }

    header .app-nav ul li {
        width: 100%;
    }

    header .bar {
        display: block;
        width: 25px;
        height: 2px;
        background-color: #e6005c;
        margin: 4px;
    }

    header .blog-header-content .blog-header-inner-content {
        width: 100%;
        display: grid;
    }

    header .blog-header-inner-content .app-blog-name img {
        width: 20%;
    }

    header .app-mobile-nav {
        cursor: pointer;
        padding: 20px 0 0;
        position: absolute;
        right: 10px;
        top: 10px;
    }

    section .blog-article-image {
        background-size: cover !important;
    }

    section .blog-article-content {
        width: 100%;
    }

    section .blog-article-name {
        margin: 10px;
    }

    section .blog-article-name .app-main-btn {
        width: 100% !important;
    }

    section .blog-article-name h4,
    section .blog-article-name p {
        font-size: 15px;
    }

    section .blog-header-options {
        justify-content: space-between;
        border-radius: 0px;
    }

    section .blog-header-options span {
        display: block;
        padding: 5px;
        display: flex;
    }

    section .article-author {
        padding: 0 !important;
        margin: 10px !important;
    }

    section .blog-header-options i {
        font-size: 15px !important;
    }

    section .blog-header-options small {
        display: block;
        padding: 5px 0 0 5px;
        font-size: 10px;
    }

    section .blog-article-body pre {
        margin: 10px;
        text-align: justify;
    }

    section .blog-article-comments {
        margin: 10px;
    }

    section .app-banking-content,
    section .app-profile-content,
    .app-profile-content,
    section .writing-content,
    section .app-banking-content .app-main-btn,
    section .app-profile-content .app-main-btn,
    .app-profile-content .app-main-btn,
    section .writing-content .app-main-btn {
        width: 100% !important;
    }

    section .app-profile-content .app-main-btn,
    .app-profile-content .app-main-btn {
        margin: 0 auto;
        width: 95% !important;
    }

    section .personal-details-form,
    section .personal-details-form select,
    section .personal-details-form input {
        width: 100%;
    }

    section .app-profile-pic-container {
        margin: 0 auto;
        width: 50%;
        height: 190px;
        background-size: cover !important;
        background-repeat: no-repeat;
        border: 5px solid #e6005c;
        top: 200px;
        position: unset !important;
        transform: unset !important;
        background: #ffffff;
        color: #e6005c;
        text-align: center;
        border-radius: 50%;
    }

    section .app-profile-content .bi-images {
        transform: translate(-50%, -128px);
    }

    section .app-profile-banner img {
        width: 100% !important;
    }

    section .app-banking-content,
    section .app-banking-content {
        width: 95% !important;
    }

    section .writing-content .app-main-btn {
        width: 95% !important;
    }

    section .image-view .view-container {
        width: 245px;
        height: 245px;
    }
}